@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.policy-results-container {
    text-align: center;
}

.graph-title {
    font-size: var(--font-md);
    color: var(--textGray);
    font-weight: 600;
}

.least-compliant-entity {
    color: var(--ibPrimaryDark);
    font-weight: 600;
    font-size: var(--font-sm);
}

.finding-additional-data-container {
    padding: 1rem 0;
}

.finding-additional-data-label {
    color: var(--darkGray);
    font-size: var(--font-sm);
}

.finding-additional-data-text {
    color: var(--darkGray);
    font-size: var(--font-lg);
    margin-bottom: 1.6rem;
}

.source-question-container {
    padding: 2rem;
}

.source-question-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.source-question {
    color: var(--ibAccent);
    padding: 1rem;
    border-radius: 18px;
    border: 1px solid var(--ibAccent);
    margin-left: 1rem;
}

.source-question-back {
    color: var(--linkColor);
    font-size: var(--font-sm);
    cursor: pointer;
}
