@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.f-xxl {
    font-size: var(--font-xl);
}

.f-xl {
    font-size: var(--font-xl);
}

.f-lg {
    font-size: var(--font-lg);
}

.f-md {
    font-size: var(--font-md);
}

.f-sm {
    font-size: var(--font-sm);
}

.f-xs {
    font-size: var(--font-xs);
}

.f-xxs {
    font-size: var(--font-xxs);
}
