@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.container {
    padding: 1rem 2rem 0 2rem;
}

.header {
    background: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
}

.header-title {
    font-size: var(--font-xxl);
    color: var(--black);
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-xl);
    font-weight: 700;
    color: var(--dark);
    border-bottom: 1px solid var(--antd-borderGray);
    padding: 2rem;
}

.tab-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

.card-header-smaller {
    font-size: var(--font-lg);
}

.card-body {
    padding: 2rem;
}

.contact-card {
    margin: 10px;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 20rem;
}

.table-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table-cell {
    font-size: var(--font-sm);
    display: flex;
    align-items: center;
}

.table-cell-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 25px;
    align-items: center;
}

.toggle {
    font-size: 12px;
}

.item-title {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
}

.header-label {
    font-weight: 700;
    font-size: larger;
}

.widget-panel {
    margin-top: 2em;
}

.tableAlignTop {
    vertical-align: top;
}

.rightAlign {
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin-left: auto;
    padding-right: 1rem;
}

.indented {
    margin-left: 20px;
}

.editIcon {
    color: #1890ff;
}

.filters {
    width: 100%;
    background: var(--blue-gradient);
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}

.filters-title {
    display: flex;
    align-items: center;
    font-size: var(--font-xl);
    color: var(--white);
    font-weight: 700;
    padding: 1rem 0 1rem 2rem;
    border-bottom: 1px solid var(--white);
}

.filters-body {
    display: flex;
    align-items: center;
    padding: 2rem;
}

.filter-chips-container {
    display: flex;
    flex-flow: row wrap;
    margin-left: -2rem;
    margin-top: -2rem;
    padding: 0 2rem 2rem 2rem;
}

.section-underline {
    border-bottom: 1px solid #f0f0f0;
}
