@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  width: 100%;
  height: 100%;
  min-height: 30rem;
}

.base-pane {
  background-color: var(--antd);
  flex: auto;
}

.primary-content-pane {
  background: var(--white);
  min-height: 360px;
}

.header {
  background: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
}

.header-title {
  font-size: var(--font-xxl);
  color: var(--black);
}

.header-subtitle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.header-subtitle {
  color: var(--gray);
  font-size: var(--font-md);
}

.tabs-container {
  margin: 0 3rem;
}

.submit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 3rem 0 3rem;
}

.show-unanswered-container {
  display: flex;
  align-items: center;
}

.show-unanswered {
  color: var(--black0_4);
  font-size: var(--font-md);
  margin-left: 1.6rem;
}

.progress-container {
  padding: 1rem 3rem;
}

.progress-label {
  font-size: var(--font-md);
  color: var(--black0_4);
}

.group-error {
  color: var(--error);
  font-weight: 700;
}

.question-container {
  min-height: 20rem;
  padding: 3rem 3rem 3rem 6rem;
  border-bottom: 1px solid var(--antd-borderGray);
}

.question-container.even {
    background: var(--fog);
}

.question-container.error {
    border: 3px solid var(--warning);
}

.error + .error {
  border-top: none;
}

.question-label {
  font-size: var(--font-xxl);
  color: var(--black0_8);
}

.question-hint {
  font-size: var(--font-md);
  margin-top: 1rem;
}

.enum-option {
  margin: 1rem 1rem 1rem 0;
  user-select: none;
  min-height: 4rem;
  padding: 0 1.5rem;
  font-size: var(--font-lg);
  border: 1px solid var(--antd-borderGray);
  border-radius: 4px;
  outline: none;
  background: var(--white);
}

.enum-option.disabled {
    cursor: not-allowed;
}

.enum-option.enabled {
    cursor: pointer;
}

.enum-option.enabled:hover {
    color: #1890ff;
    border: 1px solid var(--antd-blue);
}

.enum-option.enabled:focus {
    color: var(--antd-blue);
    border: 1px solid var(--antd-blue);
}

.selected {
  border: 1px solid var(--antd-blue);
  background: var(--antd-blue);
  color: var(--white);
}

.selected.enabled:hover {
    background: var(--antd-lightBlue);
    border: 1px solid var(--antd-lightBlue);
    color: var(--white);
}

.selected.enabled:focus {
    background: var(--antd-lightBlue);
    color: var(--white);
}

.question-user-container {
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: space-between;
}

.question {
  width: 100%;
  max-width: 55rem;
}

.question-type {
  margin-top: 2rem;
}

.more-info-container {
  margin-top: 2rem;
}

.more-info-label {
  font-size: var(--font-md);
  margin-bottom: 1rem;
}

.more-info-label.required {
    font-weight: 700;
    opacity: 0.85;
}

.more-info-label.error {
    opacity: 0.85;
    color: var(--error);
}

.updated-by {
  font-size: var(--font-md);
  color: var(--deepGray);
}

.user {
  border-top: 1px solid var(--black0_2);
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.date {
  font-size: var(--font-md);
  color: var(--black0_6);
}

.card-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 -2rem 2rem -2rem;
}

.card-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-header-title {
  font-size: var(--font-xl);
  font-weight: 700;
}

.grade-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.overall-grade {
  font-size: var(--font-sm);
  color: var(--darkGray);
  margin-right: 0.8rem;
}

.group-score {
  font-size: var(--font-sm);
  color: var(--darkGray);
  margin-right: 1rem;
  white-space: nowrap;
  text-align: right;
  width: 12rem;
}

.loe-score {
  font-size: var(--font-sm);
  color: var(--darkGray);
  margin-right: 1rem;
  white-space: nowrap;
  text-align: right;
  width: 6rem;
}

.percentage-container {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.percentage {
  width: 100%;
  height: 1rem;
  margin: 1.5rem 0;
  background: var(--fog);
}

.percentage-text {
  margin: 0 1rem;
  color: var(--darkGray);
  font-size: var(--font-sm);
}

.policy-container {
  display: flex;
  flex-direction: row;
}

.policy {
  margin-top: 2rem;
  padding: 2rem;
  border-bottom: 1px solid var(--antd-borderGray);
  background: var(--fog);
  cursor: pointer;
}

.policy-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.policy-text.expanded {
    white-space: normal;
}

.policy-label {
  font-weight: 700;
}

.findings-container {
  display: flex;
  justify-content: space-between;
  margin: 0 -2rem;
}

.finding {
  flex: 1;
}

.findings-title-container {
  font-size: var(--font-xl);
  color: var(--black);
  font-weight: 700;
  margin: 4rem -2.4rem;
  padding: 1.8rem 2.4rem;
  border-top: 1px solid var(--antd-borderGray);
  border-bottom: 1px solid var(--antd-borderGray);
}

.findings-table-container {
  margin-top: 3rem;
}

.finding-additional-data-container {
  padding: 1rem 0;
}

.finding-additional-data-label {
  color: var(--darkGray);
  font-size: var(--font-sm);
}

.finding-additional-data-text {
  color: var(--darkGray);
  font-size: var(--font-lg);
  margin-bottom: 1.6rem;
}

.grade-alert {
  margin-bottom: 2rem;
}

.alert-button-container {
  margin-top: 2rem;
}

.inline-grade {
  margin: 0 0.5rem;
}

.bottom-button {
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
}

.table-buttons-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.actions-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
