@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.root-container {
  min-height: 100%;
  background: var(--white);
  padding-bottom: 2rem;
}

.registration-container {
  background-color: var(--typhoon);
  color: var(--black);
  width: 50%;
  margin: 0 auto;
}

.content-container {
  width: 100%;
  background-color: var(--typhoon);
  padding: 2em;
}

.logo-name {
  color: var(--antd-blue);
}

.login-field-container {
  margin-bottom: 4em;
}

.login-container {
  margin-bottom: 6em;
}

.error-text {
  color: var(--error);
  text-align: center;
  font-weight: 400;
}

.form-controls {
  margin-top: 3em;
}

.content-container-spinner {
  height: 38.77em;
  width: 19em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.address-display-field {
  display: flex;
  margin-top: 1.5rem;
  flex-direction: row;
  flex: 1;
  width: 100%;
}

.address-label {
  display: flex;
  font-weight: 500;
}

.address-value {
  margin-left: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.address-row {
  margin-left: 1rem;
  display: flex;
}

.activate-button-container {
  margin-top: 2rem;
  margin-bottom: 8rem;
}

.verify-email-container {
  margin-bottom: 5rem;
}

.textfield-subheading {
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.login-header {
  display: flex;
  justify-content: center;
}

.heading {
  display: flex;
  justify-content: center;
  color: rgb(103, 106, 108);
}

@media screen and (max-width: 440px) {
  .registration-container {
    width: 100%;
    margin: 0 auto;
  }
}

.organization-switcher-modal-title {
  font-weight: 700;
  font-size: var(--font-xl);
}

.organization-switcher-modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.organization-switcher-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organization-switcher-content-heading {
  font-size: var(--font-lg);
  color: var(--dark);
  margin-bottom: 2rem;
  align-self: flex-start;
}

.organization-switcher-list-container {
  overflow-y: auto;
  background-color: var(--mist);
  border: 1px solid var(--gray);
  border-radius: 5px;
  width: 100%;
  max-height: 32.1rem;
}

.organization-selection-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 2.8rem;
  width: 100%;
  height: 8.675rem;
}

.organization-selection-container:not(:last-child) {
    border-bottom: 1px solid var(--gray);
}

.organization-selection-name {
  align-self: center;
  font-weight: 700;
  font-size: var(--font-lg);
  color: var(--dim);
}

.logo {
  height: 20rem;
}

.select-all-button {
  color: var(--antd-blue);
  cursor: pointer;
  margin-bottom: 2rem;
}

.select-all-button.disabled {
    color: var(--gray);
    cursor: not-allowed;
}
