@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.card-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-header-title {
    font-size: var(--font-xl);
    font-weight: 700;
}

.portfolio-item {
    padding: 1rem;
    color: var(--linkColor);
    font-size: var(--font-md);
    font-weight: 600;
    cursor: pointer;
    border-bottom: 1px solid var(--antd-borderGray);
}

.portfolio-item.selected {
    background-color: var(--ibPrimaryDark);
    color: var(--white);
}

.portfolio-indent {
    padding-left: 1.6rem;
}

.details-container {
    padding: 1.5rem 0;
    border-bottom: 1px solid var(--antd-borderGray);
}

.details-label {
    color: var(--darkGray);
    font-size: var(--font-md);
    font-weight: 600;
    margin-bottom: 0.5rem;
}
