@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';
@import '../../../styles/styles.css';

.menu-container {
  background: var(--white);
  box-shadow: var(--antd-dropdownBoxShadow);
  border-radius: 0.125rem;
}

.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--antd-borderGray);
  padding: 1rem;
}

.ok-button {
  background: var(--antd-blue);
  color: var(--white);
  width: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ok-button:hover {
  background: var(--antd-lightBlue);
  color: var(--white);
}
