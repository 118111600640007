@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

:root {
  --containerShadow: 0 1px 0 0 var(--typhoon);
}

.content-background {
  background-color: var(--antd);
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 3rem;
}

.primary-content-pane {
  padding: 1rem 0 2rem 0;
  min-height: 360px;
  background-color: var(--white);
  border-top: 5px solid var(--blue-gradient-dark);
}

.content-header {
  padding: 1rem 2rem 2rem 2rem;
  font-size: var(--font-xl);
  border-bottom: 1px solid var(--antd-borderGray);
  font-weight: 700;
}

.profile-card {
  display: flex;
  width: 100%;
}

.profile-image-container {
  padding: 3rem 2rem 0 2rem;
  display: flex;
}

.profile-image-content {
  position: relative;
  flex: 1 1 auto;
  top: 4px;
  margin-left: 1.5rem;
  line-height: 1.375rem;
  color: var(--dim);
}

.profile-image-name {
  font-size: var(--font-lg);
  line-height: 1.75rem;
  font-weight: 700;
  color: var(--dark);
  margin-bottom: 1rem;
}

.profile-image-date {
  margin-top: 1rem;
  color: var(--dim);
}

.avatar {
  flex: 0 1 72px;
  margin-bottom: 8px;
}

.profile-avatar {
  border-radius: 72px;
  display: block;
  width: 72px;
  height: 72px;
}

.settings-group-header-container {
  height: 2.5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.settings-group-header-label {
  color: var(--dark);
  font-size: var(--font-md);
  margin: 0 1.5em;
}

.settings-group-header-edit-icon {
  margin: 0 1em;
  color: var(--gray);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 30rem;
}

.detail-container {
  margin-top: 4rem;
  padding: 0 2rem;
}

.section-header {
  display: inline-flex;
  font-size: var(--font-md);
  font-weight: 500;
  color: var(--black);
}

.attribute-list-container {
  padding: 0 1.75em;
  font-size: var(--font-md);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.attribute-list-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0.3125em 0;
}

.attribute-label {
  color: var(--gray);
  width: 40%;
}

.attribute-label.edit {
    padding-top: 0.5rem;
}

.attribute-value {
  width: 60%;
  color: var(--dark);
  display: flex;
  justify-content: flex-end;
  white-space: pre-line;
}

.attribute-group-header-container {
  position: relative;
}

.control-spinner-container {
  position: absolute;
  top: 1rem;
  right: 7rem;
  width: 100%;
}

.modal-content-container {
  display: flex;
  flex-direction: column;
}

.modal-heading {
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: var(--font-sm);
}

.modal-form-container {
  margin-top: 3rem;
}
