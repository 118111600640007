@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.versions-card {
  margin-top: 2rem;
  display: flex;
  padding: 0 2rem;
}

.version-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--dark);
  width: 100%;
  padding: 0.5rem 2.75rem;
}

.version-label {
  display: flex;
  color: var(--gray);
  font-size: var(--font-md);
}

.version-value {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: var(--font-md);
}
