@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

.table-count {
    align-items: center;
    font-weight: 600;
    font-size: var(--font-md);
}

.table-container {
    padding: 0 2rem 2rem 2rem;
}

.gradeLink a:link {
    color: var(--antd);
}

.gradeLink a:visited {
    color: var(--antd);
}
.gradeLink a:hover {
    color: var(--antd);
}
.gradeLink a:active {
    color: var(--antd);
}

.search-bar {
    border-radius: 0.4rem;
    width: 26.5rem;
}
