@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.tableAlignTop {
    vertical-align: top;
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-xl);
    font-weight: 700;
    color: var(--dark);
    border-bottom: 1px solid var(--antd-borderGray);
    padding: 2rem;
}

.card-header-smaller {
    font-size: var(--font-lg);
}

.card-body {
    padding: 2rem;
}

.widget-panel {
    margin-top: 2em;
}
