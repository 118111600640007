@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.spinner-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 10rem;
}

.header-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 7rem;
    padding: 0 2.4rem;
    border-bottom: 1px solid var(--silver);
}

.title {
    font-size: var(--font-xl);
    font-weight: 700;
}

.table-container {
    padding: 2.4rem;
}

.no-system-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.no-system-subtext {
    margin: 3rem 0 1rem 0;
}

.column-title {
    font-size: var(--font-md);
    color: var(--dark);
    font-weight: 600;
}
