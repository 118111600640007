@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.building-detail-header {
  background-color: var(--white);
  height: 6.4rem;
  padding: 0 3rem 2.6rem 3rem;
  color: var(--dark);
  font-size: var(--font-xl);
}

.building-detail-address {
  color: var(--gray);
  font-weight: 200;
  font-size: var(--font-sm);
}

.content-container {
  display: flex;
  flex: auto;
  flex-direction: row;
}

.main-pane {
  flex: auto;
  padding: 2rem 4rem;
  overflow: auto;
}

.section-container {
  border-top: 0.5rem solid;
  border-top-color: var(--ibPrimaryDark);
  /*border-image: var(--blue-gradient) 100% 0 0 0;*/
  background: var(--white);
  border-radius: 4px;
}

.portfolio-health {
  display: flex;
  flex-direction: column;
  background-color: var(--ibPrimaryDark);
  /* background: var(--blue-gradient); */
  align-items: center;
  border-radius: 4px;
  padding: 2rem;
}

.portfolio-health-title {
  font-size: var(--font-xl);
  color: var(--white);
  font-weight: 700;
  margin-bottom: 1rem;
}

.portfolio-health-reporting {
  margin-top: 2rem;
  font-size: var(--font-sm);
  color: var(--white);
  /* color: var(--black); */
}

.no-audits-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4rem 0;
}

.no-audits {
  font-family: Roboto;
  color: var(--white);
  font-size: var(--font-md);
  margin-top: 1.5rem;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.table-count {
  align-items: center;
  font-weight: 600;
  font-size: var(--font-md);
}

.table-container {
  padding: 0 2rem 2rem 2rem;
}
