@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--fog);
  color: var(--black0_8);
  font-size: var(--font-md);
  border-radius: 2px;
  padding: 1rem 2rem;

  &.secure {
    background: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  &.needs-review {
    background: #fffbe6;
    border: 1px solid #ffe58f;
  }

  &.not-secure {
    background: #fff1f0;
    border: 1px solid #ffccc7;
  }

  &.pending {
    background: var(--fog);
    border: 1px solid #757575;
  }
}
