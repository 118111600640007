.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.map {
   position: relative;
   width: 100%;
   height: 100%;
}
