
.headerText {
    font-size: 30px;
}

.displayMobile {
    display: none;
}

.displayDesktop {
    display: block;
}

@media (max-width: 800px) {
    .displayMobile {
        display: block;
    }

    .displayDesktop {
        display: none;
    }
}
