@import '../../styles/fonts.css';
@import '../../styles/colors.css';

:root {
  --containerShadow: 0 1px 0 0 var(--typhoon);
}

.content-background {
  background-color: var(--antd);
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 3rem;
}

.primary-content-pane {
  padding: 1rem 0 2rem 0;
  min-height: 360px;
  background-color: var(--white);
  border-top: 5px solid var(--blue-gradient-dark);
}

.content-header {
  padding: 1rem 2rem 2rem 2rem;
  font-size: var(--font-xl);
  border-bottom: 1px solid var(--antd-borderGray);
  font-weight: 700;
}

.content-container {
  padding: 16px 32px 16px 32px;
}

.card-title-container {
  height: 2.5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.card-title-label {
  color: var(--dark);
  font-size: var(--font-md);
  margin: 0 1.5em;
}

.container {
  padding: 1rem 2rem 0 2rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 25px;
  align-items: center;
}
.header {
  font-size: 16px;
  padding-bottom: 27px;
}

.toggle {
  font-size: 12px;
}

.item-title {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
