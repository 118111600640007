.icon {
    font-size: 72px;
}

.title {
    font-size: 24px;
    padding: 20px;
    text-align: center;
    line-height: 1.25em;
}

.value {
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 20px;
}

.container {
    padding: 40px;
}

@media screen and (max-width: 1600px) {
    .icon {
        font-size: 48px;
    }

    .title {
        font-size: 16px;
        padding: 14px;
    }

    .value {
        font-size: 24px;
        padding-bottom: 14px;
    }

    .container {
        padding: 20px;
    }
}

a:link {
    color: inherit;
}
a:visited {
    color: inherit;
}
a:hover {
    color: inherit;
}
a:active {
    color: inherit;
}
