@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.policy-title {
    color: var(--deepGray);
    font-size: var(--font-xxl);
    font-weight: 700;
}

.policy-question {
    color: var(--gray);
}

.base-pane {
    background-color: var(--antd);
    flex: auto;
}

.primary-content-pane {
    background: var(--white);
    min-height: 360px;
}

.results-content-pane {
    display: flex;
    flex-direction: row;
    background: var(--antd);
    min-height: 680px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
    width: 100%;
    height: 100%;
    min-height: 30rem;
}

.submit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
    margin-bottom: 1rem;
}

.header-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 3rem;
}

.header-container.help-text {
    justify-content: flex-end;
}

.public-link {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.link-copied {
    color: var(--gray);
}

.copied-text {
    white-space: nowrap;
}

.enter {
    opacity: 0.01;
}

.enter.enterActive {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.leave {
    opacity: 1;
}

.leave.leaveActive {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.appear {
    opacity: 0.01;
}

.appear.appearActive {
    transition: opacity 1.3s ease-in;
    opacity: 1;
}

.title {
    color: var(--dark);
    font-size: var(--font-xl);
    margin-bottom: 5rem;
    padding-left: 3rem;
}

.title.middle {
    border-top: 1px solid var(--antd-borderGray);
    margin-bottom: 1rem;
}

.subtitle {
    color: var(--dark);
    font-size: var(--font-xl);
    margin: 2rem 0;
}

.subtitle-title {
    color: var(--black);
    font-size: var(--font-md);
    font-weight: 700;
    padding: 2rem 4rem;
}

.category-header {
    color: var(--dark);
    font-size: var(--font-lg);
    margin: 2rem 4rem;
}

.score-calculation-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: var(--font-md);
    margin-top: 2rem;
    margin-left: 2rem;
}

.score-by-label {
    font-size: var(--font-lg);
    margin-top: 2rem;
    margin-left: 2rem;
}

.prefix {
    font-weight: 500;
    color: var(--gray);
    margin-right: 1ch;
}

.value {
    font-weight: 600;
}

.stats-container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.errors-container {
    margin-top: 2rem;
}

.category-error-header {
    font-size: var(--font-lg);
    margin: 2rem 0;
    font-weight: 500;
}

.question-error-container {
    margin: 1rem 0;
}

.results-evaluation-subtext {
    line-height: 1.6;
    font-size: var(--font-lg);
    letter-spacing: 0.2px;
    color: var(--dark);
}

.tree-container {
    margin-top: 2rem;
}

.tree-row-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-left: 3px solid transparent;
    height: 4.125rem;
    width: 100%;
    color: var(--dark);
    cursor: pointer;
}

.tree-row-container.selected {
    font-weight: 700;
    border-left: 3px solid var(--antd-blue);
    background-color: var(--highlight);
}

.tree-row-container:hover {
    background-color: var(--mist);
}

.tree-row-label {
    display: flex;
    user-select: none;
    line-height: 2rem;
    flex-grow: 1;
}

.tree-row-icon-container {
    margin-bottom: -0.5rem;
}

.up {
    transform: rotate(-90deg);
}

.down {
    transform: rotate(90deg);
}

.results-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.results-title-row.report {
    display: block;
}

.results-title {
    color: var(--dark);
    font-size: var(--font-xxl);
    font-weight: 700;
}

.results-title.sub-title {
    font-size: var(--font-lg);
}

.results-title.report {
    display: block;
}

.results-title-score-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.results-title-score-container.responses {
    height: 2rem;
}

.results-title-score-container.report {
    display: block;
}

.results-title-subtext {
    color: var(--dark);
    font-size: var(--font-xs);
}

.result-grade-container {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.result-grade-container.small {
    height: 26px;
    width: 26px;
}

.result-grade-container.massive {
    height: 134px;
    width: 134px;
}

.result-grade-container.report {
    display: block;
}

.grade {
    font-weight: 700;
    color: var(--white);
    font-size: var(--font-lg);
}

.grade.small {
    font-size: var(--font-md);
}

.grade.massive {
    font-size: 70px;
}

.grade.report {
    display: block;
}

.results-line-break {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--silver);
}

.line-results-container-offset {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.line-results-container-offset.report {
    display: block;
}

.line-results-container {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}

.line-results-container.modal {
    padding-right: 0.5rem;
}

.line-results-container.report {
    display: block;
}

.line-results-label {
    font-size: var(--font-md);
    color: var(--dim);
    font-weight: 400;
}

.line-results-label.report {
    position: absolute;
    color: var(--dark);
}

.line-results-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.25rem;
}

.line-results-top-row.report {
    display: block;
}

.line-results-top-end-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 0.5rem;
}

.line-results-top-end-container.report {
    display: block;
}

.line-results-top-end-score {
    display: flex;
    align-self: flex-end;
    color: var(--dark);
    font-size: var(--font-xs);
}

.line-results-top-end-score.report {
    display: block;
}

.line-results-top-end-grade {
    font-weight: 700;
    color: var(--dark);
    margin-left: 2rem;
    font-size: var(--font-md);
}

.line-results-top-end-grade.report {
    display: block;
}

.results-bar-container {
    width: 100%;
    height: 0.8rem;
    background-color: var(--fog);
}

.results-heading-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.results-heading-container.report {
    display: block;
}

.results-heading-modal-label-container {
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: var(--font-sm);
    color: var(--antd-blue);
    cursor: pointer;
    user-select: none;
}

.results-heading-modal-label-container.report {
    display: block;
}

.modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    border-top: 1px solid var(--typhoon);
    border-bottom: 1px solid var(--typhoon);
}

.modal-container.report {
    border-top: 0;
    border-bottom: 0;
    display: block;
}

.modal-content {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding: 0 2rem;
    height: 40rem;
    overflow-y: scroll;
}

.modal-content.report {
    overflow: visible;
    display: block;
}

.modal-horizontal-break {
    margin-top: 1.125rem;
    border-bottom: 1px solid var(--typhoon);
}

.modal-export-option-text {
    font-weight: 500;
    padding: 0 2rem;
    font-size: var(--font-lg);
}

.modal-export-type-select-container {
    margin: 1rem 0;
    padding: 0 2rem;
    width: 22rem;
}

.modal-export-top-button-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
}

.modal-footer {
    display: flex;
    width: 100%;
    padding-right: 0.4125rem;
    justify-content: flex-end;
}

.report-results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
}

.report-results-container.csv {
    height: 100%;
    font-size: var(--font-xl);
    align-items: center;
}

.report-results-inner-content {
    width: 70%;
    align-self: center;
}

.responses-heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.responses-heading-label-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.responses-heading-top {
    font-size: var(--font-sm);
    color: var(--gray);
}

.responses-heading-top.report {
    font-size: var(--font-md);
}

.responses-heading-bottom {
    font-size: var(--font-xl);
    font-weight: 600;
    line-height: 1.5rem;
    color: var(--dark);
}

.responses-heading-bottom.report {
    font-size: var(--font-xxl);
}

.responses-table-heading {
    margin-top: 2rem;
    border-top: 1px solid var(--gray);
    border-bottom: 1px solid var(--dark);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-transform: uppercase;
    font-size: var(--font-xs);
    font-weight: 700;
    height: 2.125rem;
    width: 100%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.responses-table-heading.report {
    font-size: var(--font-md);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.assessment-section {
    display: flex;
    flex-flow: column wrap;
    padding-left: 3ch;
    width: 60%;
}

.assessment-section.question {
    color: var(--gray);
    padding-right: 1rem;
}

.assessment-section.full {
    width: 100%;
}

.assessment-section.report {
    display: inline-block;
}

.recommendations-section {
    display: flex;
    flex-flow: column wrap;
    width: 40%;
}

.recommendations-section.question {
    color: var(--black);
}

.recommendations-section.report {
    display: inline-block;
    vertical-align: top;
}

.response-question-row-wrapper {
    display: block;
}

.response-questions-row-container {
    margin-top: 1rem;
    padding-bottom: 1rem;
    font-size: var(--font-xs);
    border-bottom: 1px solid var(--gray);
    display: flex;
    flex-flow: row nowrap;
}

.response-questions-row-container.report {
    font-size: var(--font-md);
    margin-top: 0;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    display: block;
    border-top: 2px solid var(--gray);
    border-bottom: 2px solid var(--gray);
    page-break-inside: avoid;
}

.response-question-label {
    color: var(--black);
}

.response-question-value {
    color: var(--black);
    font-weight: 600;
}

.report-container {
    height: 100%;
    background-color: var(--white);
}

.header {
    background: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
}

.header-title {
    font-size: var(--font-xxl);
    color: var(--black);
}

.header-subtitle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-subtitle {
    color: var(--gray);
    font-size: var(--font-md);
}

.dates-container {
    display: flex;
}

.date-title {
    font-size: var(--font-md);
    color: var(--gray);
    text-align: right;
}

.date {
    font-size: var(--font-xxl);
    color: var(--black);
    text-align: right;
}

.survey-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -1rem;
}

.health-container {
    flex: 1;
    margin: 0 0 1rem 1rem;
}

.health-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 11rem;
    cursor: pointer;
}

.table-health-status {
    color: var(--antd-blue);
    font-size: var(--font-md);
}

.table-health-status-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.overview-container {
    flex: 1;
    margin: 0 0 1rem 1rem;
}

.assignees-container {
    flex: 1;
    margin: 0 0 1rem 1rem;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
}

.card-body {
    height: 16rem;
}

.card-body.scroll {
    overflow-y: auto;
}

.card-title {
    font-size: var(--font-lg);
    margin-right: 1rem;
}

.overview-body {
    display: flex;
}

.progress-container, .critical-findings-container {
    flex: 1;
    white-space: nowrap;
}

.critical-findings-container {
    padding-left: 1rem;
}

.critical-findings-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.progress-title, .critical-findings-title {
    font-size: var(--font-md);
    color: var(--gray);
    margin-bottom: 6px;
    margin-right: 1rem;
}

.progress {
    display: flex;
    flex-direction: row;
    width: 12rem;
}

.csv-export {
    display: flex;
    align-items: flex-end;
}

.percentage, .critical-findings {
    font-size: var(--font-xxl);
    color: var(--black);
}

.percentage {
    margin-right: 1rem;
    display: inline-block;
}

.ratio {
    color: var(--darkGray);
    font-size: var(--font-lg);
    line-height: 3rem;
    display: inline-block;
}

.user-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.user-list-name {
    display: flex;
    flex-direction: row;
}

.user-name {
    display: flex;
    align-items: center;
    font-size: var(--font-md);
}

.health-history-modal-container {
    max-height: 25rem;
    overflow-y: scroll;
    padding: 1rem;
}

.health-history-container {
    display: flex;
    flex-direction: row;
}

.health-status {
    font-family: Roboto;
    font-size: var(--font-md);
    width: 20%;
    padding: 2px 0 0 10px;
}

.health-comment {
    width: 80%;
    padding-left: 1rem;
}

.comment-header, .comment {
    font-family: Roboto;
    font-size: var(--font-sm);
}

.comment {
    margin-top: 5px;
}

.current-health {
    height: 2rem;
    width: 14rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.current-health-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.health-status-option {
    color: var(--gray);
    font-size: var(--font-sm);
    line-height: var(--font-md);
}

.current-health-comment {
    height: 6rem;
    overflow-y: auto;
    font-size: var(--font-md);
    margin-top: 6px;
}

.health-history-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.show-unanswered-container {
    display: flex;
    align-items: center;
}

.show-unanswered {
    color: var(--black0_4);
    font-size: var(--font-md);
    margin-left: 1.6rem;
}

.criticality-select-container {
    width: 24rem;
    padding: 1rem 2rem;
}

.criticality-label {
    font-size: var(--font-lg);
    align-self: flex-end;
}

.criticality-badge-container {
    display: flex;
    margin-bottom: 0.3rem;
}

.criticality-badge-container.report {
    font-size: var(--font-sm);
}

.criticality-dropdown-label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.criticality-dropdown-label-text {
    margin-left: 0.8rem;
}

.tabs-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
}

.tab-bar {
    margin-right: 5rem;
}

.progress-label {
    font-size: var(--font-md);
    color: var(--black0_4);
}

.category-error {
    color: var(--error);
}

.multi-instance-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0;
    padding-right: 3rem;
}

.question-container {
    min-height: 20rem;
    padding: 3rem 3rem 3rem 17rem;
}

.question-container.even {
     background: var(--fog);
}

.question-container.error {
     border: 3px solid var(--warning);
}

.question-container.no-bottom-border {
     border-bottom: none;
}

.error + .error {
    border-top: none;
}

.question-user-container {
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
}

.question {
    width: 100%;
    max-width: 55rem;
}

.question-type {
    margin-top: 2rem;
}

.question-label {
    white-space: pre-wrap;
    font-size: var(--font-xxl);
    color: var(--black0_8);
}

.question-hint {
    font-size: var(--font-md);
    margin-top: 1rem;
}

.additional-context-container {
    margin-top: 3rem;
}

.additional-context-label {
    font-size: var(--font-md);
    margin-bottom: 1rem;
}

.answered-by {
    font-size: var(--font-md);
    color: var(--deepGray);
}

.user {
    border-top: 1px solid var(--black0_2);
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.surveyHeaderComponent {
    height: 330px;
}

.bracket-container {
    display: grid;
    grid-template-columns: 20px 25px 1fr;
    grid-gap: 0;
    border-radius: 6px;
    padding: 1rem;
}

.bracket-child {
    padding-left: 0;
}

.content {
    margin-left: 3px;
}

.bracket {
    border-left: 2px solid;
    border-top: 2px solid;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 2px solid;
}

.vertical-icons {
    margin-top: 5px;
    margin-right: 2px;
    margin-left: 0;
    writing-mode: vertical-rl;
    text-orientation: upright;
    cursor: pointer;
}

.vertical {
    margin: 5px 2px 5px 0;
    writing-mode: vertical-rl;
    text-orientation: upright;
    letter-spacing: -2px;
    cursor: pointer;
}

.rotate {
    font-weight: 700;
    transform: rotate(-90deg);
}

.bracket-child.content {
    position: relative;
}

.iconsBottomAligned {
    position: absolute;
    bottom: 0;
    left: 0;
}

.conditionQuestionSelect {
    width: 375px;
}

.conditionOptionSelect {
    width: 220px;
}

.health-overview-container {
    flex: 2 0 auto;
    margin-right: 2rem;
    margin-bottom: 2rem;
}

.health-overview-header {
    line-height: 36px;
}

.health-status-title-container {
    display: flex;
    flex-direction: row;
}

.critical-findings-icon {
    cursor: pointer;
    margin-left: 5px;
}

.date-table-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    cursor: pointer;
}

.assignees-table-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.assignees {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 4rem;
}

.submitted {
    display: flex;
    flex-flow: row nowrap;
    color: var(--inactive);
    align-items: center;
}

.overview-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: center;
}

.overview-total-container {
    display: flex;
    align-items: center;
}

.overview-total {
    font-size: 24px;
    color: var(--black0_8);
}

.overview-total-title {
    font-size: var(--font-sm);
    color: var(--black0_4);
    margin-left: 6px;
}

.overview-card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-height: 8rem;
}

.overview-title {
    font-size: var(--font-sm);
    color: var(--black0_4);
    margin-top: 2px;
}

.stat {
    font-family: Roboto;
    font-size: 24px;
    color: var(--black0_8);
}

.stat.health-stat {
    margin-left: 2rem;
}

.overviews-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-right: -2rem;
}

.current-date-label {
    font-size: var(--font-lg);
    color: var(--black0_8);
    margin-bottom: 0.5rem;
}

.current-date {
    font-size: var(--font-md);
    color: var(--black0_6);
    margin-bottom: 3rem;
}

.date-field-label {
    font-size: var(--font-md);
    color: var(--black0_6);
    margin-bottom: 0.5rem;
}

.table-icon {
    font-size: var(--font-lg);
}

.overview-section {
    display: flex;
    flex-direction: column;
    flex: 2;
    border-right: 1px solid var(--ash);
}

.overview-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 4rem 4rem 4rem;
    flex: 1;
}

.overview-content-container {
    width: 100%;
}

.graph-title {
    font-size: var(--font-md);
    color: var(--textGray);
    font-weight: 600;
}

.graph-title.no-bold {
    font-weight: 500;
}

.completed-audits-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.overview-section.compliance {
    flex: 3;
}

.policy-counts-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.justify-right {
    text-align: right;
}

.findings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.finding-labels {
    margin-right: 3rem;
}

.finding-label {
    font-size: var(--font-sm);
    color: var(--antd-blue);
    margin-right: 4rem;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
}

.finding {
    display: flex;
    align-items: center;
    height: 5rem;
}

.finding-label-subtext {
    font-size: var(--font-sm);
    line-height: var(--font-sm);
}

.source-question-container {
    padding: 2rem;
}

.source-question-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.source-question {
    color: var(--antd-blue);
    padding: 1rem;
    border-radius: 18px;
    border: 1px solid var(--antd-blue);
    margin-left: 1rem;
}

.source-question-back {
    color: var(--antd-blue);
    font-size: var(--font-sm);
    cursor: pointer;
}

.policy-results-container {
    text-align: center;
}

.least-compliant-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--antd-borderGray);
}

.least-compliant-header {
    font-size: var(--font-md);
    color: var(--black);
    font-weight: 700;
    margin-left: 1rem;
}

.least-compliant-entity-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.least-compliant-entity {
    color: var(--antd-blue);
    font-weight: 600;
    font-size: var(--font-sm);
}

.finding-additional-data-container {
    padding: 1rem 0;
}

.finding-additional-data-label {
    color: var(--darkGray);
    font-size: var(--font-sm);
}

.finding-additional-data-text {
    color: var(--darkGray);
    font-size: var(--font-lg);
    margin-bottom: 1.6rem;
}

.subtitle.graph {
    margin-bottom: 2rem;
    margin-top: -4rem;
}

.rightBorderFullWidth {
    padding-right: 10px;
    border-right: 2px solid rgb(221, 221, 221);
    width: 100%;
}

.launch-modal-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.launch-modal-label {
    width: 12rem;
}

.launch-modal-data {
    margin-left: 1rem;
}

.no-system-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.no-system-subtext {
    margin: 3rem 0 1rem 0;
}

.portfolio-health {
    display: flex;
    flex-direction: column;
    background-color: var(--ibPrimaryDark);
    /* background: var(--blue-gradient); */
    align-items: center;
    border-radius: 4px;
    padding: 2rem;
}

.portfolio-health-title {
    font-size: var(--font-xl);
    color: var(--white);
    font-weight: 700;
    margin-bottom: 1rem;
}

.portfolio-health-reporting {
    margin-top: 2rem;
    font-size: var(--font-sm);
    color: var(--white);
    /* color: var(--black); */
}

.no-audits-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4rem 0;
}

.no-audits {
    font-family: Roboto;
    color: var(--white);
    font-size: var(--font-md);
    margin-top: 1.5rem;
}

.centered-cell {
    display: flex;
    justify-content: center;
}
