.icon {
    font-size: 48px;
}

.eventIcon {
    max-width: 48px;
    max-height: 48px;
}

.title {
    font-size: 24px;
    padding: 10px;
    text-align: center;
    width: 100%;
    line-height: 1.25em;
}

.value {
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 20px;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.container {
    padding: 20px;
    width: 100%;
}

@media screen and (max-width: 1600px) {
    .icon {
        font-size: 36px;
    }

    .eventIcon {
        max-width: 36px;
        max-height: 36px;
    }

    .title {
        font-size: 16px;
        padding: 6px;
    }

    .value {
        font-size: 24px;
        padding-bottom: 14px;
    }

    .container {
        padding: 14px;
    }
}
