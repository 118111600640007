.logo {
    height: 20rem;
    margin-bottom: 4rem;
}

.navLogoLarge {
    height: auto;
    width: auto;
    object-fit: contain;
    max-height: 100px;
    max-width: 180px;
    margin-top: 1rem;
    margin-bottom: 4rem;
}

.navLogoSmall {
    height: auto;
    width: auto;
    object-fit: contain;
    max-height: 100px;
    max-width: 50px;
    margin-top: 1rem;
    margin-bottom: 4rem;
}
