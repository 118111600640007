@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.title {
  font-weight: 700;
  font-size: var(--font-xl);
  padding-left: 0.7rem;
}

.collapse-title {
  padding-bottom: 0.8rem;
}

.description {
  padding-bottom: 1.6rem;
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.spinner-container {
  height: 100%;
  width: 100%;
  min-height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
