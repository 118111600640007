@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.findings-container {
  padding: 0 2rem;
}

.finding-header-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.finding-header {
  color: var(--darkGray);
  font-size: var(--font-md);
  font-weight: 600;
}

.findings {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.finding-label {
  font-size: var(--font-sm);
  color: var(--darkGray);
  margin-right: 1rem;
  white-space: nowrap;
  text-align: right;

  &.group {
    width: 12rem;
  }

  &.criticality {
    width: 4rem;
  }

  &.level-of-effort {
    width: 6rem;
  }
}

.finding-bar-container {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.finding-bar {
  display: flex;
  width: 100%;
  height: 1rem;
  margin: 1.5rem 0;
  background: var(--fog);
  margin-right: 2rem;
}
