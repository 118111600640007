@import '../../../../styles/fonts.css';

.container {
    padding: 1rem 2rem 0 2rem;
}

.header-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 25px;
    align-items: center;
}
.header {
    font-size: 16px;
    padding-bottom: 27px;
}

.edit-button {
    margin-right: 0.5rem;
}
