@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.tableAlignTop {
    vertical-align: top;
}

.base-pane {
    background-color: var(--antd);
    flex: auto;
}

.primary-content-pane {
    border-top: 0.5rem solid;
    border-image: var(--blue-gradient) 100% 0 0 0;
    border-radius: 0.4rem;
    background: var(--white);
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-xl);
    font-weight: 700;
    color: var(--dark);
    border-bottom: 1px solid var(--antd-borderGray);
    padding: 2rem;
}

.card-header-smaller {
    font-size: var(--font-lg);
}

.card-body {
    padding: 2rem;
}

.widget-panel {
    margin-top: 2em;
}

.gradeLink a:link {
    color: var(--antd);
}

.gradeLink a:visited {
    color: var(--antd);
}
.gradeLink a:hover {
    color: var(--antd);
}
.gradeLink a:active {
    color: var(--antd);
}

.legendText {
    font-family: Nirmala, Roboto;
    font-size: var(--font-xs);
}

.tableTitle {
    vertical-align: top;
    height: 100%;
}

.search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

.search-bar {
    border-radius: 0.4rem;
    width: 26.5rem;
}

.table-count {
    align-items: center;
    font-weight: 600;
    font-size: var(--font-md);
}

.table-container {
    padding: 0 2rem 2rem 2rem;
}
