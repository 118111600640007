@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.spinner-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.number-selected-container {
    display: flex;
    justify-content: flex-end;
    color: var(--textGray);
    font-size: var(--font-sm);
    font-weight: 600;
    padding: 1rem;
}

.input-container {
    padding: 0 1rem;
}

.menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.menu-item-text {
    overflow-wrap: anywhere;
}

.buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--antd-borderGray);
    padding: 1rem;
}

.divider {
    height: 1px;
    width: 100%;
    background: var(--antd-borderGray);
}

.dropdown-text {
    font-size: var(--font-sm);
    color: var(--textGray);
    padding: 1rem;
    width: 100%;
    word-wrap: break-word;
    text-align: center;
}

.ok-button {
    background: var(--antd-blue);
    color: var(--white);
    width: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ok-button:hover {
    background: var(--antd-lightBlue);
    color: var(--white);
}
