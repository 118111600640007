@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.region-container {
  padding: 1.5rem 3rem 3rem 3rem;
  background-color: var(--white);
}

.region-title {
  font-weight: 400;
  color: var(--dark);
  font-size: var(--font-xxl);
}

.region-subtitle {
  color: var(--gray);
  font-weight: 200;
  font-size: var(--font-sm);
  margin-bottom: 2rem;
}

.region-list-background {
  background-color: var(--antd);
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  width: 100%;
}

.region-list-container {
  flex-direction: column;
  border-top: 5px solid;
  border-image: var(--blue-gradient) 100% 0 0;
  background-color: var(--white);
}

.region-list-header {
  border-bottom: 1px solid var(--ash);
}

.region-header-text {
  font-weight: 700;
  font-size: var(--font-xl);
}

.table-container {
  padding: 2rem;
}

.search-bar {
  border-radius: 0.4rem;
  width: 26.5rem;
}

.search-count-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.table-count {
  align-items: center;
  font-weight: 600;
  font-size: var(--font-md);
}

.edit-button {
  margin-right: 0.5rem;
}
