@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.cve-card-row {
    margin-bottom: 20px;
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
}

.cve-card {
    margin-top: 0;
    margin-bottom: 20px;
    border-top-color: black;
    border-top-style: solid;
    border-top-width: 2px;
    background-color: var(--white);
    padding: 15px 15px 15px 15px
}

.cve-information-value-container {
    font-size: 14px;
    margin-bottom: 10px;
}

.cve-information-value-title {
    font-weight: 700;
}

.cve-information-value-value {
}

.cve-card-title {
    color: #253841;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 15px;
}

.summaryText {
    font-size: 16px;
}

.scoreContainer {
    display: block;
    padding: 20px;
}

.score {
    font-size: 38px;
    font-weight: 700;
    white-space: nowrap;
    margin: 0 0 10px 0;
    padding: 0;
    color:  var(--white);
}

.maxScore {
    font-size: 20px;
    font-weight: 700;
    margin: 25px 0 10px 5px;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    color:  var(--white);
}

.scoreLabel {
    font-size: 15px;
    margin: 0 0 10px;
    display: block;
    color:  var(--white);
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.valueBlock {
    background-color: #eee;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 400;
    line-height: 1.1;
    padding: 10px;
}

.valueBlockLabel {
    font-weight: 400;
    line-height: 1.1;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 16px;
}

.valueBlockValue {
    color: var(--white);
    display: inline;
    padding: 5px 5px 5px 5px;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    margin-right: 10px;
}
