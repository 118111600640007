@import '../styles/fonts.css';
@import '../styles/colors.css';

.criticality-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  background: var(--white);
  color: var(--black);
  border-radius: 10px;
  padding: 0 1rem;
  font-weight: 700;
  font-size: var(--font-sm);
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.criticality-indicator {
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
}
