@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.card-header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}

.info-row {
  margin-bottom: 2.4rem;
}

.info-label {
  font-size: var(--font-md);
  font-weight: 700;
  color: var(--dim);
}

.info-content {
  font-size: var(--font-md);
  color: var(--dark);
}

.sidebar-content {
  padding: 2rem;
}

.contact-block {
  margin-bottom: 2.4rem;
  line-height: 2.4rem;
}

.contact-title {
  font-weight: 700;
}

.title {
  font-size: var(--font-xl);
  font-weight: 700;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer.form {
    justify-content: flex-end;
}

.list-header {
  font-size: var(--font-md);
  margin-bottom: 2rem;
}

.list-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.list-item-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list-item-title {
  font-weight: 700;
}

.list-item-detail {
  padding-right: 1rem;
}

.list-item-buttons {
  display: flex;
}

.no-system-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}

.no-system-subtext {
  margin: 3rem 0 2rem 0;
}
