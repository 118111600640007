@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.horizontal > div:first-child {
  margin-right: 0.3rem;
}

.horizontal > div:nth-child(2) {
  margin-left: 0.3rem;
}
