@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.content {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.base-pane {
  display: flex;
  flex: auto;
  flex-direction: row;
}

.main {
  flex: auto;
  padding: 2rem 4rem;
}

.sidebar-content {
  padding: 2rem;
}

.map-container {
  height: 25rem;
  width: 100%;
}

.card-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header-title {
  font-size: var(--font-xl);
  font-weight: 700;
}

.portfolio-item {
  padding: 1rem;
  color: var(--linkColor);
  font-size: var(--font-md);
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px solid var(--antd-borderGray);
}

.portfolio-item.selected {
    background-color: var(--ibPrimaryDark);
    color: var(--white);
}

.portfolio-indent {
  padding-left: 1.6rem;
}

.details-container {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--antd-borderGray);
}

.details-label {
  color: var(--darkGray);
  font-size: var(--font-md);
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.portfolio-health {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--ibPrimaryDark);
  align-items: center;
  border-radius: 4px;
  padding: 2rem;
}

.portfolio-health-title {
  font-size: var(--font-xl);
  color: var(--white);
  font-weight: 700;
  margin-bottom: 1rem;
}

.portfolio-health-reporting {
  margin-top: 2rem;
  font-size: var(--font-sm);
  color: var(--white);
}

.portfolio-health-link {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  color: var(--white);
  font-size: var(--font-md);
  font-weight: 700;
  cursor: pointer;
}

.no-audits-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4rem 0;
}

.no-audits {
  font-family: Roboto;
  color: var(--white);
  font-size: var(--font-md);
  margin-top: 1.5rem;
}

.audit-overview-container {
  display: flex;
  flex-direction: row;
}

.audit-overview-section {
  flex: 2;
  border-right: 1px solid var(--antd-borderGray);
  margin-right: 2rem;
  padding-right: 2rem;
}

.audit-overview-section.small {
    flex: 1;
}

.audit-overview-section:last-child {
  margin-right: 0;
  padding-right: 0;
  border: none;
}

.audit-overview-title {
  font-size: var(--font-md);
  color: var(--black);
  font-weight: 700;
  margin-bottom: 1rem;
}

.audit-overview-subtitle {
  font-size: var(--font-sm);
  color: var(--darkGray);
  margin-bottom: 0.5rem;
}

.audit-overview-link {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: var(--linkColor);
  font-size: var(--font-md);
  font-weight: 500;
  margin-top: 4rem;
  cursor: pointer;
}

.policy-compliance {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}

.backup-status {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}

.spaced {
  display: flex;
  justify-content: space-between;
}

.audit-tile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--ash);
  border-radius: 5px;
  min-height: 9rem;
  padding: 1rem 3rem;
  width: 100%;
  background-color: var(--white);
  margin-bottom: 2rem;
}

.audit-tile-icon-container {
  display: flex;
  align-items: center;
  padding-right: 3rem;
  height: 100%;
}

.audit-tile-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.audit-tile-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.audit-tile-heading {
  display: flex;
  font-weight: 700;
  font-size: var(--font-xl);
  color: var(--black);
}

.audit-tile-content {
  display: flex;
  font-weight: 500;
  color: var(--dark);
}

.audit-tile-controls-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.audit-modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.audit-modal-title {
  font-weight: 700;
  color: var(--black);
}

.audit-modal-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem 2rem 2rem;
}

.audit-modal-content-heading {
  display: flex;
  font-weight: 700;
  color: var(--dim);
  margin-bottom: 1rem;
}

.least-compliant-container {
  display: flex;
  flex-direction: row;
}

.least-compliant-section {
  flex: 2;
  border-right: 1px solid var(--antd-borderGray);
  margin-right: 6rem;
  padding-right: 6rem;
}

.least-compliant-section:last-child {
  margin-right: 0;
  padding-right: 0;
  border: none;
}

.least-compliant-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 0 2rem 0.5rem;
  border-bottom: 1px solid var(--antd-borderGray);
}

.least-compliant-header {
  font-size: var(--font-md);
  color: var(--black);
  font-weight: 700;
}

.least-compliant-average-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 2rem 1rem;
  border-bottom: 1px solid var(--antd-borderGray);
}

.least-compliant-average {
  font-size: var(--font-sm);
  font-weight: 700;
  color: var(--darkGray);
}

.least-compliant-entity-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.least-compliant-entity {
  font-size: var(--font-sm);
}

.least-compliant-entity.empty {
    color: var(--black);
    font-size: var(--font-lg);
}

.least-compliant-entity.link {
    cursor: pointer;
}

.launch-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.launch-modal-label {
  width: 12rem;
}

.launch-modal-data {
  margin-left: 1rem;
}
