@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.collapse-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 37.5rem;
  top: 8rem;
  background: var(--ibAccent);
  color: var(--white);
  font-weight: 600;
  z-index: 1000;
  padding: 0.5rem;
  height: 2.5rem;
  width: 8.5rem;
  border-radius: 0 0 4px 4px;
  transform: rotate(270deg);
  cursor: pointer;
  transition: left 0.5s;
}

.collapse-button.collapsed {
    left: -3rem;
}
