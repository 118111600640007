@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.spinner-container {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.base-pane {
    background-color: var(--antd);
    flex: auto;
}

.primary-content-pane {
    border-radius: 0.6rem;
    background: var(--white);
}

.filters {
    width: 100%;
    background: var(--blue-gradient);
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}

.filters-title {
    display: flex;
    align-items: center;
    font-size: var(--font-xl);
    color: var(--white);
    font-weight: 700;
    padding: 1rem 0 1rem 2rem;
    border-bottom: 1px solid var(--white);
}

.filters-body {
    display: flex;
    align-items: center;
    padding: 2rem;
}

.filter-chips-container {
    display: flex;
    flex-flow: row wrap;
    margin-left: -2rem;
    margin-top: -2rem;
    padding: 0 2rem 2rem 2rem;
}

.title {
    color: var(--black);
    font-size: var(--font-xl);
    font-weight: 700;
    padding: 2rem;
    border-bottom: 1px solid var(--antd-borderGray);
}

.title.middle {
    border-top: 1px solid var(--antd-borderGray);
}

.title.graph {
    margin-bottom: 2rem;
}

.overview-container {
    display: flex;
    flex-direction: row;
    padding: 2rem;
    margin-top: 4rem;
}

.overview-content {
    flex: 1;
    padding: 0 2rem;
    border-right: 1px solid var(--antd-borderGray);
}

.overview-content:last-child {
    border-right: none;
}

.policy-results-container {
    text-align: center;
}

.graph-title {
    font-size: var(--font-md);
    color: var(--textGray);
    font-weight: 600;
    margin-bottom: 1rem;
}

.graph-title.no-bold {
    font-weight: 500;
}

.least-compliant-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.least-compliant-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--antd-borderGray);
}

.least-compliant-header {
    font-size: var(--font-md);
    color: var(--black);
    font-weight: 700;
    margin-left: 1rem;
}

.least-compliant-average-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0 2rem 1rem;
    border-bottom: 1px solid var(--antd-borderGray);
}

.least-compliant-average {
    font-size: var(--font-sm);
    font-weight: 700;
    color: var(--darkGray);
}

.least-compliant-entity-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.least-compliant-entity {
    color: var(--antd-blue);
    font-weight: 600;
    font-size: var(--font-sm);
}

.least-compliant-entity.empty {
    color: var(--black);
    font-size: var(--font-lg);
}

.table-container {
    padding: 4rem 2rem 2rem 2rem;
}

.table-chips-container {
    display: flex;
    flex-flow: row wrap;
    padding: 1rem 0;
    margin-left: -2rem;
    margin-top: -2rem;
}

.source-question-container {
    padding: 2rem;
}

.source-question-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.source-question {
    color: var(--antd-blue);
    padding: 1rem;
    border-radius: 18px;
    border: 1px solid var(--antd-blue);
    margin-left: 1rem;
}

.source-question-back {
    color: var(--antd-blue);
    font-size: var(--font-sm);
    cursor: pointer;
}

.subtitle {
    color: var(--black);
    font-size: var(--font-md);
    font-weight: 700;
}

.subtitle.graph {
    margin-bottom: 2rem;
    margin-top: -4rem;
}

.finding-additional-data-container {
    padding: 1rem 0;
}

.finding-additional-data-label {
    color: var(--darkGray);
    font-size: var(--font-sm);
}

.finding-additional-data-text {
    color: var(--darkGray);
    font-size: var(--font-lg);
    margin-bottom: 1.6rem;
}

.pagination-container {
    display: flex;
    justify-content: flex-end;
}

.pagination-container.top {
    margin-bottom: 2rem;
}

.pagination-container.bottom {
    margin-top: 2rem;
}

.export-button-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

.actions-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 -2rem 2rem -2rem;
}

.card-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-header-title {
    font-size: var(--font-xl);
    font-weight: 700;
}

.findings-container {
    display: flex;
    justify-content: space-between;
    margin: 0 -2rem;
}

.finding {
    flex: 1;
}

.page-break {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-md);
    color: var(--textGray);
    font-weight: 700;
    margin: 2rem 0;
    padding: 2rem 3rem;
    border-top: 1px solid var(--antd-borderGray);
    border-bottom: 1px solid var(--antd-borderGray);
}

.section {
    padding: 0 3rem;
}

.percentage-container {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.percentage {
    width: 100%;
    height: 1rem;
    margin: 1.5rem 0;
    background: var(--fog);
}

.percentage-text {
    margin: 0 1rem;
    color: var(--darkGray);
    font-size: var(--font-sm);
}

.grade-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.overall-grade {
    font-size: var(--font-sm);
    color: var(--darkGray);
    margin-right: 0.8rem;
}

.group-score {
    font-size: var(--font-sm);
    color: var(--darkGray);
    margin-right: 1rem;
    white-space: nowrap;
    text-align: right;
    width: 12rem;
}

.loe-score {
    font-size: var(--font-sm);
    color: var(--darkGray);
    margin-right: 1rem;
    white-space: nowrap;
    text-align: right;
    width: 6rem;
}

.finding-header-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.finding-header {
    color: var(--darkGray);
    font-size: var(--font-md);
    font-weight: 600;
    margin-top: 1.5rem;
}

.findings {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.finding-label {
    font-size: var(--font-sm);
    color: var(--darkGray);
    margin-right: 1rem;
    white-space: nowrap;
    text-align: right;
}

.finding-label.group {
     width: 12rem;
    max-width: 12rem;
    min-width: 12rem;
    margin-top: 1rem;
}

.finding-label.criticality {
     width: 4rem;
    max-width: 4rem;
    min-width: 4rem;
    margin-top: 1rem;
 }

.finding-label.level-of-effort {
     width: 6rem;
    max-width: 6rem;
    min-width: 6rem;
    margin-top: 1rem;
 }

.finding-bar-container {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.finding-bar {
    display: flex;
    width: 100%;
    height: 1rem;
    margin: 1.5rem 0;
    background: var(--fog);
    margin-right: 2rem;
}

.finding-bar-clean {
    width: 100%;
    height: 1rem;
    margin: 1.5rem 0;
    background: var(--fog);
    margin-right: 2rem;
}
