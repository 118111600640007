@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  flex: auto;
}

.base-pane {
  display: flex;
  flex: auto;
  flex-direction: row;
}

.main {
  flex: auto;
  background: var(--antd);
  padding: 2rem 4rem;
}

.tabs-container {
  margin-top: 0.5rem;
  padding: 0 3rem;
  border: none;
}

.section {
  padding: 0 3rem;
}

.page-break {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-md);
  color: var(--textGray);
  font-weight: 700;
  margin: 2rem 0;
  padding: 2rem 3rem;
  border-top: 1px solid var(--antd-borderGray);
  border-bottom: 1px solid var(--antd-borderGray);
}

.modal-content {
  font-size: var(--font-lg);
}

.card-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-xl);
  font-weight: 700;
  color: var(--dark);
}

.empty-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 31rem;
  padding: 5rem;
  background: var(--blue-gradient);
  color: var(--white);
}

.empty-banner-title {
  font-size: var(--font-xl);
  font-weight: 700;
}

.empty-banner-text {
  font-size: var(--font-md);
  font-weight: 600;
  margin-top: 2rem;
}

.empty-banner-buttons {
  margin-top: 2rem;
}

.empty-banner-content {
  max-width: 31rem;
}

.section-subtitle {
  font-size: var(--font-md);
  color: var(--textGray);
  font-weight: 700;
}

.tiles-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem -1rem;
}

.no-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem 2rem;
}

.no-content-text {
  max-width: 40rem;
  font-size: var(--font-md);
  color: var(--textGray);
  text-align: center;
  margin: 2rem 0;
}

.audit-breakdown-link {
  font-size: var(--font-md);
  cursor: pointer;
  color: var(--ibPrimaryLight);
  font-weight: 500;
}

.table-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.finding-additional-data-container {
  padding: 1rem 0;
}

.finding-additional-data-label {
  color: var(--darkGray);
  font-size: var(--font-sm);
}

.finding-additional-data-text {
  color: var(--darkGray);
  font-size: var(--font-lg);
  margin-bottom: 1.6rem;
}

.backup-statuses-container {
  padding-left: 2rem;
}

.backup-status-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 1.4rem;
}

.backup-status-label {
  color: var(--black0_6);
  font-size: var(--font-md);
}

.backup-status-label.bold {
    font-weight: 700;
    margin-right: 0.5rem;
}

.backup-status-label.subtitle {
    margin-top: 1.5rem;
}

.backup-status {
  cursor: pointer;
  color: var(--antd-blue);
  font-weight: 600;
  margin-left: 0.5rem;
}

.overview-tiles-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 -1rem;
}

.overview-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  border: 1px solid var(--antd-borderGray);
  box-shadow: 0 2px 4px var(--antd-borderGray);
  height: 22rem;
  margin: 2rem 1rem;
  font-size: var(--font-md);
}

.overview-tile.actions {
    background: var(--blue-gradient);
    border: none;
    color: var(--white);
}

.overview-tile-title {
  font-size: var(--font-md);
  color: var(--textGray);
  font-weight: 700;
  margin-bottom: 0.2rem;
}

.overview-tile-subtitle {
  font-size: var(--font-md);
  color: var(--textGray);
}

.overview-tile-text {
  max-width: 30rem;
}

.overview-tile-menu {
  position: absolute;
  top: 1rem;
  right: 0;
}

.server-status {
  cursor: pointer;
  color: var(--antd-blue);
  font-weight: 600;
}

.section-container {
  border-top: 0.5rem solid;
  border-top-color: var(--ibPrimaryDark);
  /* border-image: var(--blue-gradient) 100% 0 0 0; */
  background: var(--white);
  border-radius: 4px;
}
