@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem;
}

.content {
  width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image {
  height: 25rem;
  margin-bottom: 3rem;
}

.title {
  font-size: 3.6rem;
  color: #141238;
  font-weight: 700;
  margin-bottom: 1rem;
}

.text {
  color: var(--black0_4);
  font-size: var(--font-lg);
  margin-bottom: 2rem;
}
