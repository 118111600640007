@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.container {
    display: flex;
    justify-content: center;
}

.card-header-title {
    font-size: var(--font-xl);
    font-weight: 700;
}

.card-header-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
}

.spinner-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-system-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
}

.no-system-subtext {
    margin: 3rem 0 2rem 0;
}
