@import '../../styles/colors.css';

.container {
  height: 100%;
  background: var(--white);
}

.login-field-container {
  margin-bottom: 4em;
}

.login-container {
  margin-bottom: 6em;
}

.error-text {
  color: var(--error);
  text-align: center;
  font-weight: 400;
}

.login-header {
  display: flex;
  justify-content: center;
}

.heading {
  display: flex;
  justify-content: center;
  color: rgb(103, 106, 108);
}
