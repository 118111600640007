/* ADD EDIT MODAL */
.modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 3rem;
}

.avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.25rem;
}

.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.horizontal > div:first-child {
    margin-right: 0.3rem;
}

.horizontal > div:nth-child(2) {
    margin-left: 0.3rem;
}
