@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.content {
    /*
    display: flex;
    flex-direction: column;
    flex: auto;
    */
    height: 100vh;
    width: 100vh;
}

.content-container {
    display: flex;
    flex: auto;
    flex-direction: row;
    height: 100vh;
    width: 100vh;
}

.main-pane {
    flex: auto;
    padding: 2rem 4rem;
    overflow: auto;
    height: 100vh;
    width: 100vh;
}
