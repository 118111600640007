@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.header {
    background: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
}

.header-title {
    font-size: var(--font-xxl);
    color: var(--black);
}

.base-pane {
    background-color: var(--antd);
    flex: auto;
}

.primary-content-pane {
    border-top: 0.5rem solid;
    border-image: var(--blue-gradient) 100% 0 0 0;
    border-radius: 0.4rem;
    background: var(--white);
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-xl);
    font-weight: 700;
    color: var(--dark);
    border-bottom: 1px solid var(--antd-borderGray);
    padding: 2rem;
}

.card-header-smaller {
    font-size: var(--font-lg);
}

.card-body {
    padding: 2rem;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 20rem;
}

.table-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table-cell {
    font-size: var(--font-sm);
    display: flex;
    align-items: center;
}

.table-cell-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.control-table-cell {
    display: flex;
    font-size: var(--font-sm);
    width: 100%;
    padding-left: 0.75rem;
    align-items: center;
    justify-content: center;
    height: 4rem;
}

.email-table-cell {
    font-size: var(--font-sm);
    display: flex;
    align-items: center;
    height: 3.5rem;
    font-weight: 600;
}

.create-user-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 2rem;
    padding-right: 1rem;
}

.modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 3rem;
}

.avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.control-field {
    display: flex;
    flex: 1;
    width: 100%;
}

.modal-content-container {
    display: flex;
    flex-direction: column;
}

.modal-heading {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: var(--font-sm);
}

.modal-form-container {
    margin-top: 3rem;
}

.modal-spinner-container {
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-input {
    margin-top: 2rem;
}

.modal-content {
    margin-top: 1.9rem;
}

.modal-content-subtitle {
    font-weight: 600;
}

.assigned-work-title {
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
}

.assigned-work-subtitle {
    font-weight: 600;
}

.export-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-xl);
    font-weight: 500;
    color: var(--dark);
    border-bottom: 1px solid var(--antd-borderGray);
    padding: 2rem;
}

.section-body {
    padding: 2rem;
}

.actionIcon {
    cursor: pointer;
}

.table-chips-container {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 2rem;
    margin-left: -2rem;
    margin-top: -2rem;
}

.filters {
    width: 100%;
    background: var(--blue-gradient);
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}

.filters-title {
    display: flex;
    align-items: center;
    font-size: var(--font-xl);
    color: var(--white);
    font-weight: 700;
    padding: 1rem 0 1rem 2rem;
    border-bottom: 1px solid var(--white);
}

.filters-body {
    display: flex;
    align-items: center;
    padding: 2rem;
}

.filter-chips-container {
    display: flex;
    flex-flow: row wrap;
    margin-left: -2rem;
    margin-top: -2rem;
    padding: 0 2rem 2rem 2rem;
}
