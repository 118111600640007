@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.primary-content-pane-page {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    background-color: var(--antd);
    border: 0 1px 0 0 var(--antd);
    min-height: 240px;
    width: 100%;
}

.widget-content-pane {
    border-top: 0.5rem solid;
    border-top-color: var(--ibPrimaryDark);
    /* border-image: var(--blue-gradient) 100% 0 0 0; */
    border-radius: 0.4rem;
    background: var(--white);
    height: 100%;
    padding-top: 68px;
}

.widget-content-pane-no-title {
    border-top: 0.5rem solid;
    border-image: var(--blue-gradient) 100% 0 0 0;
    border-radius: 0.4rem;
    background: var(--white);
    height: 100%;
}

.info-widget-content-pane {
    border-radius: 15px 15px 15px 15px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--gray);
    background-color: var(--info-widget-background-light);
}

.info-widget-content-pane-child-container {
    padding: 10px;
}

.widget-panel {
    background: var(--white);
    overflow: hidden;
    height: 100%;
    border-bottom: 5px solid var(--white);
}

.info-widget-panel {
    overflow: hidden;
}

.widget-header-wrapper {
    height: 68px;
    margin-top: -68px;
}

.widget-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: Nirmala, Roboto;
    text-transform: uppercase;
    font-size: var(--font-xl);
    color: var(--dark);
    border-bottom: 1px solid var(--antd-borderGray);
    padding: 2rem;
    height: 68px;
    margin-top: -68px;
}

.widget-card-body {
    padding: 2rem;
    height: 100%;
    overflow-y: auto;
}

.widget-static {
    background: var(--white);
}

.allowVerticalScroll {
    height: 100%;
    overflow-y: auto;
}
