@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.container {
  padding: 2rem;
}

.card-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-xl);
  font-weight: 700;
}

.card-header-title {
  font-size: var(--font-xl);
  font-weight: 700;
}

.card-section-label {
  font-weight: 700;
  color: var(--dim);
  margin-bottom: 1rem;
}

.card-section-container {
  padding: 1.5rem 2.4rem;
  border-bottom: 1px solid var(--antd-borderGray);
}

.card-section-container.last {
    border-bottom: none;
}

.card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-label {
  font-size: var(--font-md);
  font-weight: 700;
  color: var(--dim);
}

.card-value {
  font-size: var(--font-md);
  color: var(--dark);
  margin-left: 0.5rem;
}

.card-value.backup {
    cursor: pointer;
    color: var(--antd-blue);
    font-weight: 700;
}

.status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-description {
  font-size: var(--font-md);
  color: var(--black0_6);
  margin-bottom: 1rem;
}

.modal-footer.flex-end {
    justify-content: flex-end;
}

.backup-statuses-container {
  padding-left: 2rem;
}

.backup-status-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 1.4rem;
}

.backup-status-label {
  color: var(--black0_6);
  font-size: var(--font-md);
}

.backup-status-label.bold {
    font-weight: 700;
    margin-right: 0.5rem;
}

.btn-footer {
  border-radius: 5px;
}

.btn-container {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.btn {
  height: 12rem;
  width: 14.4rem;
  font-size: 1.2rem;
  white-space: normal;
  padding: 0;
  background-color: var(--mist);
  border-radius: 5px;
  margin-right: 2rem;
}

.btn:nth-child(4n) {
  margin-right: 0;
}

.system-type {
  color: var(--ibPrimaryLight);
  font-weight: 600;
  font-size: var(--font-md);
}
