.label {
  display: flex;
  flex-direction: row;
}

.buttons {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e8e8e8;
  padding: 7px 8px;
}

.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
