@import '../styles/colors.css';
@import '../styles/fonts.css';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.component-container {
  background-color: var(--antd);
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 3rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
}

.title-container {
  background: var(--white);
}

.title {
  font-family: Nirmala, Roboto;
  font-size: var(--font-xxl);
  color: var(--black0_8);
}

.subtitle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subtitle {
  color: var(--black0_4);
  font-size: var(--font-md);
}

.subtitle.separator {
  margin: 0 0.3rem;
}
