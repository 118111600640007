@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 3rem;
}

.modal-content-container {
    display: flex;
    flex-direction: column;
}

.modal-heading {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: var(--font-sm);
}

.modal-form-container {
    margin-top: 3rem;
}

.modal-spinner-container {
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-input {
    margin-top: 2rem;
}

.modal-content {
    margin-top: 1.9rem;
}

.modal-content-subtitle {
    font-weight: 600;
}
