.icon {
    font-size: 48px;
}

.title {
    font-size: 24px;
    padding: 10px;
    text-align: center;
    line-height: 1.25em;
}

.value {
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 5px;
}

.container {
    padding: 5px;
}

a:link {
    color: inherit;
}
a:visited {
    color: inherit;
}
a:hover {
    color: inherit;
}
a:active {
    color: inherit;
}
