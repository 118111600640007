:root {

  /* IBBranding Colors */
  --ibPrimaryDark: #122048;
  --ibPrimaryLight: #42b9ea;
  --ibAccent: #f04b24;
  --ibLightGray: #f5f5f5;

  /*
    IB UI Common
      NOTE: Link CSS defined in index.html & theme.js
  */

  --linkColor: #19a6e0;
  --totalsBadge: var(--ibAccent);

  /* IB Event Criticality:  NOTE: Values repeated in colors.js */
  --eventCriticalityBackgroundCritical: #f44336;
  --eventCriticalityBackgroundMajor: #ed7d31;
  --eventCriticalityBackgroundMinor: #d79d80;
  --eventCriticalityBackgroundWarning: #ffc600;
  --eventCriticalityBackgroundInfo: #088fa3;
  --eventCriticalityBackgroundOK: #3fa142;
  --eventCriticalityForegroundCritical: #fff;
  --eventCriticalityForegroundMajor: #fff;
  --eventCriticalityForegroundMinor: #fff;
  --eventCriticalityForegroundWarning: #fff;
  --eventCriticalityForegroundInfo: #fff;
  --eventCriticalityForegroundOK: #fff;

  /* IB Ticket Type Colors */
  --ticketTypeChange: #ffc600;
  --ticketTypeIncident: #ed7d31;
  --ticketTypeProblem: #f44336;
  --ticketTypeService: #d79d80;
  --ticketTypeProject: #088fa3;

  /* IB Compliant / Non-Compliant */
  --ibCompliant: var(--eventCriticalityBackgroundOK);
  --ibNonCompliant: #ed3620;

  /* IB Success / Fail */
  --ibSuccess: var(--ibCompliant);
  --ibFailed: var(--ibNonCompliant);

  /* IB Risk */
  --ibHealthOffTrack: var(--eventCriticalityBackgroundCritical);
  --ibHealthAtRisk: var(--eventCriticalityBackgroundMajor);
  --ibHealthOnTrack: var(--eventCriticalityBackgroundOK);
  --ibHealthNeeded: var(--eventCriticalityBackgroundWarning);

  /* IB Completion Status */
  --ibCompletionOverdue: var(--eventCriticalityBackgroundCritical);
  --ibCompletionNotStarted: var(--eventCriticalityBackgroundMajor);
  --ibCompletionInProgress: var(--eventCriticalityBackgroundInfo);
  --ibCompletionComplete: var(--eventCriticalityBackgroundOK);

  /* IB Free / Used (Disk Space) */
  --ibFreeSpace: var(--ibCompliant);
  --ibUsedSpace: var(--ibNonCompliant);

  /* IB Background Colors */
  --ibBackgroundOverdue: #ed3620;

  --darkBlue: #006bf2;
  --error: #ff4d4f;
  --warning: #ffc8c5;
  --redHighlight: #fff1f0;
  --darkGray: #778088;
  --textGray: #676a6c;
  --lightGray: #f3f3f3;
  --deepGray: #666;
  --teal: #23c6c8;
  --blueBackground: #2f4050;

  /* Brand Colors */
  --red: #ed3620;
  --blue: #3498db;
  --turquoise: #41b4a6;
  --orange: #ff8f00;
  --green: #3fa142;
  --purple: #9209ae;
  --violet: #3e4eb8;
  --navy: #0048c7;

  /* Gradients */
  --turquoise-gradient-dark: #3aaea0;
  --turquoise-gradient-light: #72e0d1;
  --turquoise-gradient: linear-gradient(90deg, var(--turquoise-gradient-dark), var(--turquoise-gradient-light));
  --blue-gradient-dark: #309cd9;
  --blue-gradient-light: #5ecbee;
  --blue-gradient: linear-gradient(90deg, var(--blue-gradient-dark) 0%, var(--blue-gradient-light) 100%);

  /* Antd Colors */
  --antd-blue: #1890ff;
  --antd-lightBlue: #40a9ff;
  --antd-borderGray: #d9d9d9;
  --antd: #f0f2f5;

  /* Neutral Colors */
  --white: #fff;
  --mist: #fafafa;
  --fog: #f5f5f5;
  --typhoon: #eee;
  --ash: #ddd;
  --silver: #ccc;
  --gray: #999;
  --dim: #555;
  --dark: #333;
  --black: #000;
  --inactive: #bbb;

  /* Opacity gradient colors */
  --black0_8: rgba(0, 0, 0, 0.85);
  --black0_6: rgba(0, 0, 0, 0.65);
  --black0_4: rgba(0, 0, 0, 0.45);
  --black0_2: rgba(0, 0, 0, 0.25);
  --white0_8: rgba(255, 255, 255, 0.85);
  --white0_6: rgba(255, 255, 255, 0.65);
  --white0_4: rgba(255, 255, 255, 0.45);
  --white0_2: rgba(255, 255, 255, 0.25);

  /* Work Order Status Colors */
  --unassigned: var(--gray);
  --assigned: var(--orange);
  --inProgress: var(--navy);
  --completed: var(--green);
  --closed: var(--ash);
  --positive: var(--green);
  --negative: var(--red);
  --highlight: rgba(0, 0, 0, 0.05);
  --link: var(--linkColor);
  --boxshadow: var(--black0_2);
  --lowOpacityBoxShadow: rgba(0, 0, 0, 0.2);

  /* Criticality colors */
  --criticality-compliant: #1890ff;
  --criticality-positive: #3fa142;
  --criticality-critical: #970000;
  --criticality-high: #f44336;
  --criticality-medium: #fc6c1a;
  --criticality-low: #ffc600;
  --criticality-info: #5b5b5b;

  /* Health status colors - taken from antd Badge component */
  --health-needed: #d9d9d9;
  --health-off-track: #ff4d4f;
  --health-at-risk: #faad14;
  --health-on-track: #3fa142;

  --info-widget-background-light: #dceaf7;
}
