.container {
  padding: 1rem 2rem 0 2rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}
  .header {
    font-size: 16px;
    padding-bottom: 27px;
  }

  .toggle {
    font-size: 12px;
  }

.item-title {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
