.container {
  height: 100%;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.avatar {
  display: flex;
}
