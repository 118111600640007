@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.audit-overview-container {
    display: flex;
    flex-direction: row;
}

.audit-overview-section {
    margin-right: 2rem;
    padding-right: 2rem;
}

.audit-overview-padded {
    margin-left: 2rem;
    padding-left: 2rem;
}

.audit-overview-title {
    font-size: var(--font-md);
    color: var(--black);
    font-weight: 700;
    margin-bottom: 1rem;
}

.policy-compliance {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
}

.policy-responses {
    margin-top: 4rem;
}

.audit-overview-subtitle {
    font-size: var(--font-sm);
    color: var(--darkGray);
    margin-bottom: 0.5rem;
}

.spaced {
    display: flex;
    justify-content: space-between;
}

.audit-overview-section.small {
    flex: 1;
}

.audit-overview-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: var(--ibPrimaryLight);
    font-size: var(--font-md);
    font-weight: 500;
    margin-top: 4rem;
    cursor: pointer;
}

.no-audits-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4rem 0;
}

.no-audits {
    font-family: Roboto;
    color: var(--white);
    font-size: var(--font-md);
    margin-top: 1.5rem;
}

.overview-section {
    display: flex;
    flex-direction: column;
    flex: 2;
}

.overview-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 4rem 4rem 4rem;
    flex: 1;
}

.overview-content-container {
    width: 100%;
}

.graph-title {
    font-size: var(--font-md);
    color: var(--textGray);
    font-weight: 600;
}

.graph-title.no-bold {
    font-weight: 500;
}

.completed-audits-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.finding {
    display: flex;
    align-items: center;
    height: 5rem;
}

.findings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.finding-labels {
    margin-right: 3rem;
}

.finding-label {
    font-size: var(--font-sm);
    color: var(--linkColor);
    margin-right: 4rem;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
}

.finding-label-subtext {
    font-size: var(--font-sm);
    line-height: var(--font-sm);
}
