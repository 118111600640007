@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.submitted {
    display: flex;
    flex-flow: row nowrap;
    color: var(--inactive);
    align-items: center;
}

.critical-findings-title {
    display: flex;
    flex-direction: row;
}

.critical-findings-icon {
    cursor: pointer;
    margin-left: 5px;
}
